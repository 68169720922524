import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import { FaUser, FaArrowRightFromBracket } from 'react-icons/fa6';

const Header = () => {
  const isLoggedIn = useSelector((state) => state.profile.isLoggedIn);

  return (
    <header className="top-0 left-0 right-0 w-full border-b shadow-sm bg-white z-50">
      <div className="p-3 flex justify-between items-center max-w-[768px] w-full mx-auto">
        <div className="flex items-center">
          <Link to="/"><img src="/images/logo-tui3gang.png" alt="Túi 3 Gang" className="h-12" /></Link>
          <span className="text-2xl font-bold text-green-700">Tui3gang.com</span>
        </div>
        <div>
          {isLoggedIn ? (
            <Link to="/profile">
            <button className="flex items-center text-green-700">
            <FaUser size={26} />
            </button>
            </Link>
          ) : (
            <Link to="/login">
              <button className="bg-white text-green-700 px-2 py-1 rounded flex items-center text-lg border border-green-700">
              <FaArrowRightFromBracket size={16} />&nbsp;Đăng nhập
              </button>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;