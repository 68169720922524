import React, { useEffect, useState } from 'react';
import { getTopUsers } from '../../api/user/topUserMonthApi';
import formatCurrency from '../utils/formatCurrency';
import { FaUser } from 'react-icons/fa6';

// Hàm để trả về đường dẫn ảnh tương ứng với từng merchantName
const getMerchantLogo = (merchantName) => {
  switch (merchantName.toLowerCase()) {
    case 'shopee':
      return '/images/brands/logo-shopee.png';
    case 'lazada':
      return '/images/brands/logo-lazada.png';
    case 'tiktok':
      return '/images/brands/logo-tiktokshop.png';
    case 'tikivn':
      return '/images/brands/logo-tiki.png';
    case 'fpt_longchau':
      return '/images/brands/logo-nhathuoclongchau.png';
    default:
      return '/images/brands/logo-other.png';
  }
};

const indexRank = {
  0: 'font-bold text-2xl sm:text-3xl text-purple-500',
  1: 'font-bold text-xl sm:text-2xl text-red-500',
  2: 'font-bold text-lg sm:text-xl text-amber-500',
  3: 'font-bold sm:text-lg text-yellow-400',
  4: 'font-bold sm:text-lg text-green-500',
  5: ''
};

const TopUsersWithMerchant = () => {
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopUsers = async () => {
      setLoading(true);
      const users = await getTopUsers();
      
      // Sắp xếp người dùng theo totalRefundCredit từ lớn đến nhỏ
      const sortedUsers = users.sort((a, b) => b.totalRefundCredit - a.totalRefundCredit);
      
      setTopUsers(sortedUsers);
      setLoading(false);
    };

    fetchTopUsers();
  }, []);

  return (
    <div className="container mx-auto mt-3">
      <div className="bg-white shadow-md rounded-lg p-3">
        <div className="overflow-x-auto">
          {loading ? (
            <div className="text-center py-10 text-lg font-medium">Đang tải thông tin...</div>
          ) : (
            <table className="min-w-full">
              <tbody>
                {topUsers.map((user, index) => (
                  <tr key={user.idUser} className="border-t">
                    <td className={`px-2 text-center ${indexRank[index]}`}>{index + 1}</td>
                    <td className="py-2 flex items-center justify-between w-full">
                      <div className="flex items-center w-2/3 sm:w-1/2">
                        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full p-2">
                          <FaUser size={18} className="text-white" />
                        </div>
                        <div className="ml-2 font-medium">
                          <div>{user.fullName || `User-${user.idUser}`}</div>
                          <div className="flex items-center space-x-2">
                            <span className='text-gray-500 font-normal text-xs'>Hoàn tiền</span>
                            {user.merchantNames.map((merchant, i) => (
                              <img
                                key={i}
                                src={getMerchantLogo(merchant)}
                                alt={merchant}
                                className="w-5 h-5"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="py-2 text-right w-1/3 sm:w-1/2">
                        <span className="text-white px-2 py-1 rounded-md bg-gradient-to-r from-cyan-500 to-blue-500">
                          +{formatCurrency(user.totalRefundCredit)}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopUsersWithMerchant;