import axios from 'axios';
import { BASE_URL } from '../configs';

export const getTopUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/user/top-user`);
      return response.data.topUsers;
    } catch (error) {
      console.error('Error fetching top users:', error);
      return [];
    }
  };