import React from 'react';
import { Link } from "react-router-dom";
import { FaRegCircleQuestion } from "react-icons/fa6";
import SEOMeta from '../SEOMeta';
import { formattedMonthYear } from '../utils/formatTimeNow';
import TopUsersWithMerchant from '../other/TopUsersMonthWithMerchant';

const ShoppingCashback = () => {
  const items = [
    {
      to: "/shopee",
      imgSrc: '/images/cover-shopee.png',
      alt: 'Hoàn tiền Shopee',
      title: 'Hoàn tiền Shopee',
      cashback: 'Hoàn tiền 50%'
    },
    {
      to: "/lazada",
      imgSrc: '/images/cover-lazada.png',
      alt: 'Hoàn tiền Lazada',
      title: 'Hoàn tiền Lazada',
      cashback: 'Hoàn tiền 40%'
    },
    {
      to: "/tiktokshop",
      imgSrc: '/images/cover-tiktok.png',
      alt: 'Hoàn tiền Tiktok Shop',
      title: 'Hoàn tiền Tiktok',
      cashback: 'Hoàn tiền 50%'
    },
    {
      to: "/cashback-all",
      imgSrc: '/images/cover-all-shop.png',
      alt: 'Cashback online',
      title: 'Cashback online',
      cashback: 'Hoàn tiền 60%'
    },
  ];

  return (
    <div>
    <SEOMeta 
        title="Mua Hàng Nhận Hoàn Tiền Shopee, Lazada, TikTok Shop Tới 60%"
        description="Mua hàng trên Shopee, Lazada, TikTok Shop thông qua Túi 3 Gang bạn sẽ được nhận hoàn tiền lên tới 60%. Nhớ tạo link Shopee, Lazada, TikTok Shop và ấn Mua ngay để được nhận chiết khấu nhé."
    />
      <div className='flex justify-between items-center mt-4'>
        <p className="text-2xl font-semibold">Mua sắm hoàn tiền</p>
        <Link to="/huong-dan">
          <span className='text-green-700 flex items-center'>
            <FaRegCircleQuestion className="mr-1" />
            Hướng dẫn
          </span>
        </Link>
      </div>
      <p className="text-md mb-4 p-1 text-gray-600">Mua sắm trên Shopee, Lazada, TikTok Shop,... thông qua Tui3Gang.Com bạn sẽ nhận được tiền hoàn về tài khoản. Hoặc chia sẻ cho bạn bè mua hàng bạn cũng nhận được tiền hoàn.</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-3 mb-5">
        {items.map((item, index) => (
          <Link
            key={index}
            to={item.to}
            className="flex flex-col items-center justify-center shadow-md rounded-lg"
          >
            <img
              src={item.imgSrc}
              alt={item.alt}
              className="w-full h-auto rounded-lg mb-2"
            />
            <span className="text-center text-xl">{item.title}</span>
            <span className="text-center pb-2 text-red-500">{item.cashback}</span>
          </Link>
        ))}
      </div>
      <p className="text-2xl font-semibold mt-4">Top hoàn tiền {formattedMonthYear}</p>
      <TopUsersWithMerchant />
    </div>
  );
};

export default ShoppingCashback;