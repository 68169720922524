import React from 'react';
import { Link } from "react-router-dom";
import ImageSlider from '../other/ImageSlider';
import ListVoucherShopee from './ListVoucherShopee';
import ListVoucherLazada from './ListVoucherLazada';
import SEOMeta from '../SEOMeta';
import { IMAGES_SLIDES } from '../../api/configs';
import BoxJoinGroup from '../other/BoxJoinGroup';

const ListVouchers = () => {

  return (
    <>
        <SEOMeta 
          title="Mã Giảm Giá Shopee, Mã Giảm Giá Lazada Mới Nhất"
          description="Cập nhật mã giảm giá Shopee, mã giảm giá Lazada mới nhất! Mua hàng giảm giá còn được hoàn tiền."
        />
        <ImageSlider images={IMAGES_SLIDES} />
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-2 mt-3 mb-6">
            <Link
              to='/voucher-shopee'
              className="flex flex-col items-center justify-center shadow-md rounded-lg"
            >
              <img
                src='/images/cover-shopee.png'
                alt='Mã giảm giá Shopee'
                className="w-full h-auto rounded-lg mb-2"
              />
              <span className="text-center font-semibold text-xl pb-3">Mã giảm giá Shopee</span>
              {/* <span className="text-center pb-2 text-red-500">{item.cashback}</span> */}
            </Link>
            <Link
              to='/voucher-lazada'
              className="flex flex-col items-center justify-center shadow-md rounded-lg"
            >
              <img
                src='/images/cover-lazada.png'
                alt='Mã giảm giá Lazada'
                className="w-full h-auto rounded-lg mb-2"
              />
              <span className="text-center font-semibold text-xl pb-3">Mã giảm giá Lazada</span>
              {/* <span className="text-center pb-2 text-red-500">{item.cashback}</span> */}
            </Link>
        </div>
        <p className="text-2xl font-semibold my-5 p-2">Lấy mã giảm giá 👇</p>
        <ListVoucherShopee />
        <ListVoucherLazada />
        <BoxJoinGroup />
    </>
  );
};

export default ListVouchers;